import tw from 'twin.macro';
import React, { FC, useContext } from 'react';
import { CardContext } from '@leuven2030/framework/Card/Card';
import ImageBackground from '@leuven2030/framework/Image/ImageBackground';
import { StyleOverflowHidden } from '@leuven2030/framework/styles/general';
import { CSSObject } from '@emotion/react';
type Props = {
  src: string;
  imgixParams?: any;
  data?: any;
  height?: number;
  width?: number;
  imgStyle?: CSSObject;
};
const CardImage: FC<Props> = ({
  imgixParams,
  children,
  src,
  data,
  width = 400,
  height = 160,
  imgStyle,
  ...props
}) => {
  const { isHovering } = useContext(CardContext);
  return (
    <div
      tw="overflow-hidden w-full relative"
      css={[{ height, maxHeight: height }, StyleOverflowHidden]}
      {...props}
    >
      <ImageBackground
        tw="absolute w-full h-full transform transition-transform duration-300 ease-in-out bg-no-repeat bg-center bg-cover"
        data={data}
        src={src}
        imgixParams={{
          w: width,
          h: height + 80,
          fit: 'crop',
          ...imgixParams
        }}
        css={[isHovering && tw`scale-125`, imgStyle]}
      />
      {children}
    </div>
  );
};

export default CardImage;
