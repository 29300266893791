import { PageContext } from '@leuven2030/framework/Page/Page';
import Card from '@leuven2030/framework/Card/Card';
import CardImage from '@leuven2030/framework/Card/CardImage';
import { StyleContentContainer } from '@leuven2030/framework/Style/StyleContent';
import React, { useContext } from 'react';
import get from 'lodash/get';
import tw from 'twin.macro';
const ContentReports = () => {
  const { prismic } = useContext(PageContext);

  const reports = get(prismic, 'reports.edges') || [];
  return (
    <div tw="bg-gray-200 p-3 grid grid-cols-4 py-6" css={StyleContentContainer}>
      <div>
        <h2 tw="text-2xl text-gray-800 mb-6">Reports</h2>
        {reports.map(({ node: { cover, _meta, title } }, index) => (
          <Card prismicMeta={_meta} tw="space-y-3" key={index}>
            {cover && (
              <CardImage
                src={cover.url}
                tw="rounded-lg"
                height={260}
                imgStyle={tw`bg-gray-400 bg-contain `}
                imgixParams={{
                  fit: 'fill'
                }}
              />
            )}
            <h3 tw="text-base">{title}</h3>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default ContentReports;
