import tw from 'twin.macro';
import { css } from '@emotion/react';

export const StyleContent = tw`max-w-3xl px-3 sm:px-6 lg:max-w-3xl xl:max-w-4xl 2xl:max-w-5xl mx-auto w-full`;

export const StyleOverflowHidden = css`
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
`;

export const StyleParallaxCover = css`
  img {
    object-fit: cover;
  }
`;
