import React, { FC } from 'react';
import { useImageUrl } from '@leuven2030/framework/Image/ImageURL';

const ImageBackground: FC<{
  src: string;
  className?: string;
  data?: any;
  width?: number;
  height?: number;
  imgixParams?: any;
  alt?: string;
}> = ({ src, className, imgixParams, alt, data, ...props }) => {
  const { width, height } = props;

  function getImageData() {
    if (data) {
      return data;
    }
    return useImageUrl({
      src,
      imgixParams,
      defaultParams: {
        w: width,
        h: height
      }
    });
  }

  const { fallbackUrl, imageUrl, imgixUrl } = getImageData();

  return (
    <div
      className={`lazyload ${className}`}
      css={{
        backgroundImage: `url('${fallbackUrl}')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      }}
      data-bg={imageUrl || imgixUrl}
      {...props}
    />
  );
};

export default ImageBackground;
