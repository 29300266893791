import { gql } from '@apollo/client';
import 'twin.macro';
import PageProps from '@leuven2030/monitor/Page/PageProps';
import Page from '@leuven2030/monitor/Page/Page';
import React from 'react';
import ContentReports from '@leuven2030/monitor/Content/ContentReports';
import MonitorLayout from '@leuven2030/framework/Monitor/MonitorLayout';

const LeuvenDataHome = Page(() => {
  return (
    <MonitorLayout>
      <ContentReports />
    </MonitorLayout>
  );
});

export const getStaticProps = PageProps({
  prismic: {
    query: gql`
      {
        reports: allReports {
          edges {
            node {
              title
              cover {
                ...ImageLink
              }
              powerbi_report_id
              ...Document
            }
          }
        }
      }
    `
  }
});

export default LeuvenDataHome;
